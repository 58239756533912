const plugin = require('tailwindcss/plugin');

module.exports = plugin(function ({ addBase, theme }) {
  const typography = {
    '.btn': {
      fontSize: '13px',
      textTransform: 'uppercase',
      letterSpacing: '0.77px',
      lineHeight: 1.12,
      fontFamily: theme('fontFamily.condensed'),
    },
    '.btn-sm': {
      fontSize: '10px',
      letterSpacing: '0.82px',
      lineHeight: 1.26,
    },
    '.text-h1, .prose h1, .text-h2, .prose h2, .text-h3, .prose h3, .text-h4, .prose h4, .text-h5, .prose h5, .text-h6, .prose h6':
      {
        fontFamily: theme('fontFamily.condensed'),
        color: theme('colors.black'),
        textTransform: 'uppercase',
      },
    '.text-h1, .prose h1': {
      fontSize: '26.29px',
      [`@media (min-width: ${theme('screens.sm')})`]: {
        fontSize: '31.25px',
      },
      [`@media (min-width: ${theme('screens.md')})`]: {
        fontSize: '39px',
      },
      lineHeight: 1.2,
      letterSpacing: '0.03em',
    },
    '.text-h2, .prose h2': {
      fontSize: '22.28px',
      [`@media (min-width: ${theme('screens.sm')})`]: {
        fontSize: '25px',
      },
      lineHeight: 1.2,
      letterSpacing: '0.03em',
    },
    '.text-h3, .prose h3': {
      fontSize: '18.88px',
      [`@media (min-width: ${theme('screens.sm')})`]: {
        fontSize: '20px',
      },
      lineHeight: 1.3,
      letterSpacing: '0.03em',
    },
    '.text-h4, .prose h4': {
      fontSize: '16px',
      lineHeight: 1.3,
      letterSpacing: '0.04em',
    },
    '.text-h5, .prose h5': {
      fontSize: '13px',
      lineHeight: 1.35,
      letterSpacing: '0.06em',
    },
    '.text-h6, .prose h6': {
      fontSize: '10px',
      lineHeight: 1.45,
      letterSpacing: '0.08em',
    },
    '.text-h1.lowercase,.text-h1.normal-case,.text-h2.lowercase,.text-h2.normal-case,.text-h3.lowercase,.text-h3.normal-case,.text-h4.lowercase,.text-h4.normal-case':
      {
        letterSpacing: '0px',
      },
    '.text-h5.lowercase,.text-h5.normal-case': {
      letterSpacing: '0.03em',
    },
    '.text-h6.lowercase,.text-h6.normal-case': {
      letterSpacing: '0.04em',
    },
    '.text-h1-display,.text-h2-display,.text-h3-display,.text-h4-display,.text-h5-display,.text-h6-display': {
      fontFamily: theme('fontFamily.display'),
      color: theme('colors.black'),
    },
    '.text-h1-display': {
      fontSize: '31.55px',
      [`@media (min-width: ${theme('screens.sm')})`]: {
        fontSize: '37.5px',
      },
      [`@media (min-width: ${theme('screens.md')})`]: {
        fontSize: '47px',
      },
      lineHeight: 1.2,
    },
    '.text-h2-display': {
      fontSize: '27px',
      [`@media (min-width: ${theme('screens.sm')})`]: {
        fontSize: '30px',
      },
      lineHeight: 1.2,
    },
    '.text-h3-display': {
      fontSize: '22.66px',
      [`@media (min-width: ${theme('screens.sm')})`]: {
        fontSize: '24px',
      },
      lineHeight: 1.3,
    },
    '.text-h4-display': {
      fontSize: '19px',
      lineHeight: 1.3,
    },
    '.text-h5-display': {
      fontSize: '15px',
      lineHeight: 1.35,
      letterSpacing: '0.03em',
    },
    '.text-h6-display': {
      fontSize: '12px',
      lineHeight: 1.45,
      letterSpacing: '0.03em',
    },

    '.text-lg': {
      fontSize: '22px',
      lineHeight: 1.3,
    },
    '.text-md': {
      fontSize: '16px',
      lineHeight: 1.3,
      letterSpacing: '0.016em',
    },
    '.text-sm': {
      fontSize: '12.8px',
      lineHeight: 1.6,
      letterSpacing: '0.016em',
    },
    '.text-xs': {
      fontSize: '10.24px',
      lineHeight: 1.66,
      letterSpacing: '0.03em',
    },
  };

  addBase(typography);
});
