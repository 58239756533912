import React, { ReactNode } from 'react';
import Link from 'next/link';
import Line from './Line';
import IconArrowRight from './IconArrowRight';

interface SalesCalloutProps {
  ctaPageName: string;
  calloutId: string;
  href: string;
  title: string;
  ctaTitle?: string;
  image: ReactNode;
}

type Props = React.FC<React.HTMLAttributes<HTMLAnchorElement> & SalesCalloutProps>;

const SalesCallout: Props = ({
  image,
  title,
  ctaTitle,
  className,
  calloutId,
  ctaPageName,
  href,
  children,
  ...props
}) => {
  return (
    <Link href={href}>
      <a
        {...props}
        className={`group flex w-full cursor-pointer flex-col justify-between border border-gold p-16 transition-all duration-200 ease-in-out hover:bg-white hover:shadow-3 lg:p-32 ${
          className ? className : ''
        } tracker-callout-${ctaPageName}-${calloutId}-20190408-093833`}
      >
        <div>
          <h3
            className="text-h4 mb-8 transition-all duration-200 ease-in-out group-hover:text-gold-darker lg:mb-16"
            style={{ willChange: 'color' }}
          >
            {title}
          </h3>

          <p
            className="hyphens mb-2 break-words text-sm text-gray-darker transition-all duration-200 ease-in-out group-hover:text-gold-darker"
            style={{ willChange: 'color' }}
          >
            {children}
          </p>

          <Line className="my-16 md:my-32" />

          {ctaTitle && (
            <div
              className="mb-16 flex items-center space-x-4 font-condensed text-sm uppercase leading-tight tracking-wide text-gold-darker transition-all duration-200 ease-in-out group-hover:text-gold-darker"
              style={{ willChange: 'color' }}
            >
              <div>{ctaTitle}</div>
              <IconArrowRight className="!text-gold-darker" />
            </div>
          )}
        </div>

        <div className="-mr-16 -mb-16 flex justify-end overflow-hidden lg:-mr-32 lg:-mb-32">{image}</div>
      </a>
    </Link>
  );
};

export default SalesCallout;
