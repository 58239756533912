const theme = require('./src/tailwind/tailwind-theme-gentux.js');
const plugin = require('tailwindcss/plugin');
const safelist = require('./src/tailwind/safelist.js');
const _ = require('lodash');

module.exports = {
  mode: 'jit',
  purge: {
    enabled: process.env.ENVIRONMENT !== 'local',
    content: ['./pages/**/*.{js,ts,jsx,tsx}', './src/**/*.{js,ts,jsx,tsx}', './sanity/static/styleOptions.js'],
    safelist,
  },
  theme: theme,
  darkMode: 'media',
  variants: {
    extend: {
      cursor: ['disabled'],
      backgroundColor: ['disabled'],
      borderColor: ['disabled'],
      boxShadow: ['disabled'],
      placeholderColor: ['disabled'],
      filter: ['hover'],
      aspectRatioUtilities: ['responsive'],
    },
  },
  plugins: [
    require('@tailwindcss/aspect-ratio'),
    require('./src/tailwind/typography-gentux'),
    plugin(function ({ addUtilities, theme, e }) {
      const aspectRatioUtilities = _.map(theme('aspectRatios'), (value, key) => {
        return {
          [`.${e(`aspect-ratio-${key}`)}`]: {
            'aspect-ratio': value,
          },
        };
      });

      addUtilities(aspectRatioUtilities);
    }),
  ],
};
