import React, { ReactNode } from 'react';
import Line from './Line';
import Link from 'next/link';

interface NextStepsProps {
  salesCallout1?: ReactNode;
  salesCallout2?: ReactNode;
  ctaPageName: string;
}

type Props = React.FC<React.HTMLAttributes<HTMLDivElement> & NextStepsProps>;

const NextSteps: Props = ({ ctaPageName, className, salesCallout1, salesCallout2, ...props }) => {
  return (
    <div
      {...props}
      className={`next_steps_module p-section border-t border-gray-lighter  ${className ? className : ''}`}
    >
      <div className="container">
        <div className="row">
          <div className="col-span-12 mb-32 xs:col-span-4 xs:mb-0 lg:col-span-3 lg:col-start-2">
            <div className="space-y-32">
              <h2 className="text-h2-display">Get started.</h2>

              <Line />

              {props.children ? (
                props.children
              ) : (
                <>
                  <p className="mb-16 md:mb-64">
                    Stylish suits and tuxedos delivered straight to your door. See our collection and rent&nbsp;now.
                  </p>

                  <div className="space-y-16">
                    <Link href="/collection/[category]" as="/collection/tuxedos-and-suits">
                      <a
                        className={`btn-info btn w-full tracker-cta-${ctaPageName}-next_steps_module-view_collection-20190306-105512`}
                      >
                        View Collection
                      </a>
                    </Link>

                    <a
                      href="/app/signup/email"
                      className={`btn-default-outline btn w-full tracker-cta-${ctaPageName}-next_steps_module-create_account-20190306-105512`}
                    >
                      Create Account
                    </a>
                  </div>
                </>
              )}
            </div>
          </div>

          <div className="col-span-12 xs:col-span-7 xs:col-start-6 lg:col-span-6 lg:col-start-6">
            <h2 className="text-h2-display mb-16 xs:mb-32">Learn More.</h2>

            <div className="row place-items-stretch">
              <div className="col-span-6 flex items-stretch">{salesCallout1}</div>
              <div className="col-span-6 flex items-stretch">{salesCallout2}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NextSteps;
